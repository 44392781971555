<template>
	<ViewLayout>
		<template #header-title>
			{{ 'Data Subject Grouping Rules' | useLabels }}
		</template>
		<template #header-caption>
			View and update the grouping relationship rules for your organisations data subjects.
		</template>
		<template #content>
			<div
				class="d-flex flex-row cassie-horizontal-md"
			>
				<v-spacer />
				<PrimaryActionButton
					v-if="userFullPermissions"
					@click="showManageModal = true"
				>
					<v-icon left>
						mdi-plus
					</v-icon>
					Create
				</PrimaryActionButton>
			</div>
			<SectionCard>
				<template #title>
					{{ 'Data Subject Grouping Rules' | useLabels }}
				</template>
				<template #body>
					<DataTable
						:headers="tableHeaders"
						:items="dataSubjectGroupingRules"
						@click:row="openEditModal"
					>
						<template #item.passFields="{ item }">
							{{ item.passFields.join(', ') }}
						</template>
						<template #item.action="{ item }">
							<IconButton
								v-if="userFullPermissions"
								@click="openEditModal(item)"
							>
								{{ userFullPermissions ? 'mdi-pencil' : '' }}
							</IconButton>
							<IconButton
								v-if="item.index !== 0 && userFullPermissions"
								@click.stop.prevent="moveGroupingRuleUp(item.index)"
							>
								mdi-arrow-up
							</IconButton>
							<IconButton
								v-if="item.index !== groupingRules.length - 1 && userFullPermissions"
								@click.stop.prevent="moveGroupingRuleDown(item.index)"
							>
								mdi-arrow-down
							</IconButton>
							<IconButton
								v-if="userFullPermissions"
								@click.stop.prevent="ruleToBeDeleted = item"
							>
								mdi-trash-can
							</IconButton>
						</template>
					</DataTable>
				</template>
			</SectionCard>
			<PageActionRow v-if="showActionButtons">
				<template #actions>
					<v-spacer />
					<div class="cassie-horizontal-md">
						<SecondaryActionButton @click="hideActionButtons">
							Cancel
						</SecondaryActionButton>
						<PrimaryActionButton
							@click="saveChanges"
						>
							Save
						</PrimaryActionButton>
					</div>
				</template>
			</PageActionRow>
			<ManageGroupingRulesModal
				v-if="showManageModal"
				:grouping-rules="dataSubjectGroupingRules"
				:grouping-rule-to-edit="groupingRuleToEdit"
				@close="closeManageModal"
			/>
			<ConfirmDeleteModal
				v-if="ruleToBeDeleted"
				:entity-name="ruleToBeDeleted.passName"
				entity-type="Data Subject Grouping Rule"
				@close="ruleToBeDeleted = null"
				@delete="deleteGroupingRule"
			/>
		</template>
	</ViewLayout>
</template>
<script>
import { mapGetters } from 'vuex'
import ViewLayout from '../../../../../../shared/layouts/view-layout.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import DataTable from '../../../../../../shared/components/data-table.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import ConfirmDeleteModal from '../../../../../../shared/components/confirm-delete-modal.vue'
import PageActionRow from '../../../../../../shared/components/page-action-row.vue'
import ManageGroupingRulesModal from './manage-grouping-rules-modal.vue'
import { getGroupingRules, upsertGroupingRules, deleteGroupingRule } from '../../../../../../shared/utils/api/data-subject-grouping-rules.js'
import { GROUPING_RULES_FULL_PERMISSIONS } from '../../../../../../shared/permissions/admin-portal-permissions.js'

export default {
	components: {
		ViewLayout,
		PrimaryActionButton,
		DataTable,
		SectionCard,
		ManageGroupingRulesModal,
		IconButton,
		ConfirmDeleteModal,
		PageActionRow,
		SecondaryActionButton
	},
	data () {
		return {
			showManageModal: false,
			groupingRuleToEdit: null,
			ruleToBeDeleted: null,
			groupingRules: [],
			showActionButtons: false
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		userFullPermissions () {
			return this.productAreaPermission(GROUPING_RULES_FULL_PERMISSIONS)
		},
		tableHeaders () {
			return [
				{ text: 'ID', value: 'id', width: '10%' },
				{ text: 'Name', value: 'passName', width: '25%' },
				{ text: 'Pass Fields', value: 'passFields', width: '50%' },
				{ text: 'Action', value: 'action', width: '15%' }
			]
		},
		dataSubjectGroupingRules () {
			return this.groupingRules.map((rule, index) => ({
				...rule,
				index: index,
				passFields: rule.passFields
			}))
		}
	},
	created () {
		this.loadGroupingRules()
	},
	methods: {
		async loadGroupingRules () {
			const { data: { groupingRules } } = await getGroupingRules()
			this.groupingRules = groupingRules.sort((a, b) => a.executionOrder - b.executionOrder)
		},
		closeManageModal () {
			this.showManageModal = false
			this.groupingRuleToEdit = null
			this.loadGroupingRules()
		},
		openEditModal (selectedRule) {
			if (!this.userFullPermissions) return
			this.groupingRuleToEdit = selectedRule
			this.showManageModal = true
		},
		moveGroupingRuleDown (index) {
			this.move(this.groupingRules, index, index + 1)
			this.showActionButtons = true
		},
		moveGroupingRuleUp (index) {
			this.move(this.groupingRules, index, index - 1)
			this.showActionButtons = true
		},
		removeDropdownValue (index) {
			const groupingRules = [...this.groupingRules]
			this.$delete(groupingRules, index)
			this.groupingRules = groupingRules
		},
		applySort (array) {
			this.groupingRules = array
		},
		move (array, from, to) {
			const arrayCopy = JSON.parse(JSON.stringify(array))
			arrayCopy.splice(to, 0, arrayCopy.splice(from, 1)[0])
			return this.applySort(arrayCopy)
		},
		hideActionButtons () {
			this.showActionButtons = false
			this.loadGroupingRules()
		},
		async saveChanges () {
			const groupingRules = this.groupingRules.map((rule, index) => ({
				...rule,
				executionOrder: index + 1
			}))
			await upsertGroupingRules(groupingRules)
			this.loadGroupingRules()
			this.showActionButtons = false
		},
		deleteGroupingRule () {
			deleteGroupingRule(this.ruleToBeDeleted.id)
			this.$delete(this.groupingRules, this.ruleToBeDeleted.index)
			this.ruleToBeDeleted = null
		}
	}
}
</script>
