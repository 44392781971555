import { configApi } from '../http-client.js'

export const getGroupingRules = () => {
	return configApi.get('api/DataSubjectRules/GetGroupingRules')
}

export const upsertGroupingRules = groupingRules => {
	return configApi.post('api/DataSubjectRules/UpsertGroupingRules', groupingRules)
}

export const deleteGroupingRule = groupingRuleId => configApi.delete(`/api/DataSubjectRules/DeleteGroupingRule/${groupingRuleId}`)
